import React from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import { getSrcSet } from "gatsby-plugin-image"
import slugify from "slugify"
import BackgroundVideoSection from "../components/backgroundvideosection"

import SEO from "../components/seo"
import Layout from "../components/layout"

import "./directorList.sass"

const DirectorsList = ({ directors }) => {
  const directorEffect = e => {
    let curImg = document.querySelector("img.active")
    if (curImg) {
      curImg.classList.remove("active")
    }
    let curLink = document.querySelector("a.active")
    if (curLink) {
      curLink.classList.remove("active")
    }
    e.target.classList.add("active")
    e.target.parentNode.previousElementSibling
      .querySelector("img")
      .classList.add("active")
  }
  return (
    <>
      {directors.filter(director => director.films != null).map(director => {
        return (
          <div key={director.id} className="director">
            <div>
              <img
                srcSet={
                  getSrcSet(director.films[
                    Math.floor(Math.random() * director.films.length)
                  ].cover.localFile)
                }
                alt=""
              />
            </div>
            <div>
              <Link
                onMouseEnter={directorEffect}
                key={director.id}
                data-id={director.id}
                to={`${slugify(director.name, { lower: true })}`}
              >
                {director.name}
              </Link>
            </div>
          </div>
        )
      })}
    </>
  )
}

const DirectorsPage = ({data}) => {

  const directors = data.allContentfulDirector.nodes

  return (
    <Layout region={data.contentfulRegion.slug}>
      <SEO title="Directors" />
      <Helmet htmlAttributes={{ class: "directors" }} />
      <div className="main-wrapper">
        <BackgroundVideoSection
          height={".main-wrapper"}
          videoUrl={
            "https://player.vimeo.com/external/379017521.hd.mp4?s=c23d230c0fe297c663d72c3971790e664a7b59a4&profile_id=175"
          }
        >
          <div className="content-wrapper">
            <div className="directors-wrapper">
              <DirectorsList directors={directors} />
            </div>
          </div>
        </BackgroundVideoSection>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query DirectorList($regionId: String!) {
    contentfulRegion(id: { eq: $regionId }) {
      slug
    }
    allContentfulDirector(
      filter: { region: { elemMatch: { id: { eq: $regionId } } } }
      sort: { fields: name }
    ) {
      nodes {
        id
        name
        films {
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1280
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    allContentfulAsset(
      filter: { file: { fileName: { eq: "background-directors.jpg" } } }
    ) {
      nodes {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`

export default DirectorsPage
